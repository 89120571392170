<template>
  <ContactList type="check-deposit" @onContactClick="contactSelectHandler" @newContact="onNewContact" @back="drawerBack" />
</template>
<script>
import ContactList from '@m/contact/views/ContactList';
import { mapMutations } from 'vuex';
export default {
  components: {
    ContactList
  },
  methods: {
    ...mapMutations('checkDeposit', ['setSelectedContact']),
    /**
     * on contact select handler
     * @param {import('@m/check-deposit/store/state').CheckDepositState['selectedContact']} contact
     */
    contactSelectHandler(contact) {
      this.setSelectedContact(contact);
      this.drawerPush('CheckDeposit-form');
      // this.$router.push('/check-deposit/form');
    },
    onNewContact() {
      this.drawerReplace('CheckDeposit-newContact')
    }
  }
};
</script>
