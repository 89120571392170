<template>
  <div class="widgetContainer contactList full-width">
    <div class="navigationHeader" v-if="type !== 'contact'">
      <div class="navigationHeader__inner">
        <span class="icon-Arrow-big-left cancel" @click="back" />
        <p class="title">
          {{ $t('cotacts_list_title') }}
        </p>
      </div>
    </div>

    <div class="widgetContainer__body">
      <p class="header-1" v-if="type === 'contact'">
        {{ $t('cotacts_list_title') }}
      </p>
      <div class="contactList__search-add">
        <div class="searchInput">
          <el-input
            type="text"
            v-model="search"
            :placeholder="$t('cotacts_list_seach_placeholder')" clearable />
          <span class="icon-icon-search" />
        </div>
        <div class="add" @click="newContact">
          <p> <span class="icon-plus" />  {{ $t('cotacts_list_addContact') }}</p>
        </div>
      </div>

      <Contacts @onContactClick="onContactClick" :type="type" :searchterm="searchterm" ref="contacts" v-if="insidedrawer" />
      <Contacts :type="type" :searchterm="searchterm" ref="contacts" v-else />
    </div>
  </div>
</template>
<script>
import { debounce } from '@/utils/general';
import contactMixins from '../mixins/contact';
import Contacts from './Contacts.vue';

export default {
  name: 'ContactList',
  mixins: [contactMixins],
  props: {
    type: {
      type: String,
      default: 'contact'
    }
  },
  components: {
    Contacts
  },
  data() {
    return {
      search: null,
      searchterm: null,
      insidedrawer: false
    };
  },
  created() {
    if (this.$listeners.onContactClick) {
      this.insidedrawer = true;
    }
  },
  methods: {
    newContact() {
      this.$refs.contacts.updateSelectedContactData(null);
      this.$refs.contacts.updateCreateContactData(null);
      if(this.type==='contact') {
        this.$refs.contacts.openContactDetails();
      } else {
        this.$emit('newContact');
      }
    },
    addAccount() {
      this.$router.push('/account/create');
    },
    back() {
      if (this.type === 'check-deposit') {
        this.$emit('back');
      } else {
        this.goToDashboard();
      }
    },
    onContactClick(contact) {
      if (this.$listeners.onContactClick) {
        this.$emit('onContactClick', contact);
        return;
      }
    }
  },
  watch: {
    search: debounce(function () {
      this.searchterm = this.search.trim();
    }, 1000),
  }
};
</script>

<style lang="scss">
  .contactList {
    max-height: 100%;
    > .widgetContainer__body {
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }
    &__nav {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .back {
        cursor: pointer;
        display: flex;
        align-items: center;

        .icon-arrow-left {
          font-size: 18px;
        }

        p {
          color: #3c3c43;
          font-size: 17px;
          padding-left: 6px;
        }
      }
    }

    &__search-add {
      display: flex;

      .add {
        background-color: #fff;
        border-radius: var(--radius);
        border: 1px solid #eeeeee;
        cursor: pointer;
        margin-left: 10px;
        height: 64px;
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 14px 0 14px;
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

        &:hover {
          border-color: #C0C4CC;
        }

        @media screen and (max-width: 992px) {
          height: 48px;
        }

        span {
          padding-right: 4px;
          font-size: 14px;

          @media screen and (max-width: 992px) {
            padding-right: 12px;
          }
        }

        p {
          font-size: 16px;

          @media screen and (max-width: 992px) {
            font-size: 14px;
          }
        }
      }
    }

    .el-drawer .el-drawer__body & {
      .widgetContainer__body {
        padding-bottom: 0;
      }
    }
  }
</style>
