<template>
  <div class="skeleton-list-contact__small">
    <p class="header-1" v-if="title">
      {{ title }}
    </p>
    <el-skeleton :animated="true" :count="count">
      <template slot="template">
        <div class="content">
          <el-skeleton-item variant="rect" />
          <el-skeleton-item variant="p" />
        </div>
      </template>
    </el-skeleton>
  </div>
</template>

<script>
export default {
  props: {
    animated: {
      type: Boolean,
      required: false,
      default: true
    },
    title: {
      type: String,
      default: null,
      required: false
    },
    count: {
      type: Number,
      default: null,
      required: false
    }
  },
}
</script>

<style lang="scss">
  .skeleton-list-contact__small {
    padding-top: 17px;

    .header-1 {
      padding-bottom: 24px;
    }

    .el-skeleton {
      width: 100%;
      margin-bottom: 24px;
      display: flex;
      border-radius: 4px;
      border-top: 1px solid #eeeeee;

      .el-skeleton {
        display: inline-flex;
        gap: 16px;
      }

      .el-skeleton__rect {
        height: 54px;
        width: 54px;
        margin-bottom: 10px;
      }

      .el-skeleton__p {
        height: 20px;
        width: 80%;
      }
      
      .content {
        background: white;
        border-radius: var(--radius);
        width: 100px;
        height: 130px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 24px 16px;
        margin-right: 16px;
      }
    }
  }
</style>